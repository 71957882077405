<template>
  <div style="width: 100vw; height: 100vh" v-if="isSuccess == 'open'">
    <div class="register">
      <div class="password">
        <div class="formTitle">{{ $t("ifm.san") }}</div>
        <div style="font-size: 14px; font-weight: 500; margin-top: 20px;">{{ $t("ifm.password") }}:</div>
        <el-input type="password" clearable v-model="password"></el-input>
        <div style="font-size: 14px; font-weight: 500; margin-top: 20px">{{ $t("ifm.cpassword") }}:</div>
        <el-input type="password" clearable v-model="Cpassword"></el-input>
        <!-- <div style="font-size: 10px; color: red; margin-top: 10px">{{ tips }}</div> -->
        <el-button type="primary" style="margin-top: 20px; margin-left: 40%" @click="register">{{ $t("btn.create") }}</el-button>
      </div>
    </div>
  </div>
  <div v-else>
    <div style="font-size: 50px; text-align: center; margin-top: 50px">{{ $t("tips.url") }}</div>
  </div>
</template>

<script >
import { checkAccount, verifyAccount } from "@/services/user";
import { clearAll } from "@/utils/auth";
export default {
  name: "register",
  data() {
    return {
      password: "",
      Cpassword: "",
      tips: "",
      tipsPw: "",
      strength: "",
      isSuccess: "open",
    };
  },
  methods: {
    checkValue() {
      if (this.password !== "") {
        if (this.Cpassword !== this.password) {
          this.tips = this.$t("tips.psd");
        } else {
          this.tips = "";
        }
      } else {
        this.tips = this.$t("tips.must");
      }
    },
    register() {
      // if (this.tips) {
      //   this.$message.error(this.tips);
      //   return;
      // }
      // if (this.password === "") {
      //   this.$message.error(this.$t("tips.must"));
      //   return;
      // }
      // if (this.Cpassword === "") {
      //   this.$message.error(this.$t("tips.must"));
      //   return;
      // }
      this.$message.closeAll();
      // const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@$%^&*+#]).{12,2000}$/;
      // const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@$%^&*+#])[A-Za-z\d!@$%^&*+#]{12,2000}$/;
      const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@$%^&*+#])[A-Za-z\d!@$%^&*+# ]{12,2000}$/;

      if (!regex.test(this.password)) {
        this.$message({
          message: this.$t("tips.pwNotValid"),
          type: "error",
          duration: 0,
          showClose: true,
        });
        return;
      }
      if (this.password !== "" && this.password != this.Cpassword) {
        this.$message({
          message: this.$t("tips.pwNotMatch"),
          type: "error",
          duration: 0,
          showClose: true,
        });
        return;
      }

      let route = this.$route;
      verifyAccount({
        password: this.password,
        uuid: route.query.uuid,
        // type: route.query.type,
        // imo: route.query.imo ? route.query.imo.replace(/\s/g, "+") : "",
        // flag: route.query.flag || "",
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success("Password successfully set !");
          clearAll();
          this.$router.push("/login");
        }
      });
    },
  },
  mounted() {
    const form = {
      uuid: this.$route.query.uuid,
      // type: this.$route.query.type,
      // imo: this.$route.query.imo
      //   ? this.$route.query.imo.replace(/\s/g, "+")
      //   : "",
      // flag: this.$route.query.flag || "",
    };
    checkAccount(this.$route.query.uuid).then((res) => {
      if (res.msg == "false") {
        this.isSuccess = "close";
      } else {
        this.isSuccess = "open";
      }
    });
  },
};
</script>

<style scoped lang="less">
.register {
  width: 100%;
  height: 100%;
  background-color: rgb(241, 244, 252);
  padding-top: 100px;
  box-sizing: border-box;
  .title {
    width: 100%;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    padding-top: 100px;
  }
  .password {
    width: 400px;
    background-color: #fff;
    border-radius: 20px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    .formTitle {
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 14px;
    }
    .el-input {
      margin-top: 10px;
    }
    .rules {
      color: #888888; 
      font-size: 10px;
    }
  }
}
</style>
